<template>
  <div class="container b-card" style="height: 90vh;">
    <iframe
      :src="file_source"
      width="100%"
      height="100%"
      class=""
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      file_source: '',
      screen_size: '',
    }
  },

  created() {
    this.file_source = 'https://docs.google.com/document/d/1rK6a-rbpjqDskiK4sPaY6PELwFvJ0yQT5CXjhgUtLwA/edit?usp=sharing'
  },
}
</script>
